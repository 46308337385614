<template>
  <div>
    <div>
      <headerTop></headerTop>
    </div>
    <div>
      <headerMin :searchState="searchState"></headerMin>
    </div>
    <div class="doctorDetails_content wrap">
      <div class="dc_indexes">
        <span @click="$router.push('/')">首页</span> >
        <span @click="$router.push('/doctor/index')">线上问诊</span>
        >
        {{ this.UserArr.category_name }} >
        {{ this.UserArr.realname }}
      </div>
      <!-- 步骤 -->
      <div class="dc_step">
        <el-steps :active="state" align-center>
          <el-step title="选择医生" description></el-step>
          <el-step title="选择问诊方式" description></el-step>
          <el-step title="等待接诊" description></el-step>
          <el-step title="线上问诊" description></el-step>
        </el-steps>
      </div>
      <!-- 医生信息 -->
      <div class="dc_doctorNews red">
        <div class="userImg">
          <img v-if="!isDefalutImg" :src="UserArr.avatar" @error="isDefalutImg = !isDefalutImg" />
          <img v-else src="@/assets/image/Furtherconsultation_Defaulthead@2x.png" />
        </div>
        <div class="dc_usertxt red">
          <div class="t_title">
            <span>{{UserArr.realname}}</span>
            {{UserArr.job_name}}
            <p v-if="isFollow" class="cancle_follow" @click="setFollow">
              <img src="@/assets/image/Commoditydetails_heart_Selection@2x.png" alt />
              取消关注
            </p>
            <p v-else @click="setFollow">
              <img src="@/assets/image/Commoditydetails_heart_default@2x.png" alt />
              关注
            </p>
          </div>
          <div class="txt">{{UserArr.jigou_name}} - {{UserArr.category_name}}</div>
          <div class="txtMsg">简介: {{UserArr.jianjie ? UserArr.jianjie : '无' }}</div>
          <div class="wait_num">{{ waiting_num }}人排队</div>
        </div>
      </div>
      <!-- 问诊方式 -->
      <div class="dc_mode">
        <div class="m_title">
          <span>问诊方式</span>请根据您的需要选择适合您的在线问诊方式
        </div>
        <div class="m_tabs">
          <div @click="GoWait()" class="m_tab1">
            <i></i>
            <img src="@/assets/image/Persondetails_picture_img@2x.png" alt />
            <div class="fr">
              <p class="p1">图文问诊</p>
              <p class="p2">免费</p>
              <p class="p3">通过图片、文字进行咨询</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 评价 -->
      <div class="dc_assess">
        <div class="a_title">
          <span>患者评价</span>该医生的患者评价
        </div>
        <div class="none" v-if="commentList.length == 0">暂无评价内容</div>
        <div v-else>
          <div class="a_list" v-for="(v, i) in commentList" :key="i">
            <div class="a_user">
              <img :src="v.user_info.avatar" alt />
              <p class="one">{{ v.user_info.user_nickname }}</p>
            </div>
            <div class="a_txt">
              <p class="two p1">{{ v.content }}</p>
              <p class="p2">
                {{ v.add_time }}
                <span></span>
                问诊方式：{{ v.chat_type == 0 ? '图文咨询' : '视频咨询' }}
              </p>
            </div>
          </div>
          <div class="a_bottom" @click="getAllComment">显示全部评价</div>
        </div>
      </div>
    </div>
    <div class="bg1">
      <publicBottom></publicBottom>
    </div>
  </div>
</template>
<script>
import headerTop from "@/components/public/public_headerTop.vue";
import headerMin from "@/components/index/index_header_min";
import publicBottom from "@/components/public/public_bottom.vue";
import { getinfo } from "@/request/index"; // 医生详情
import { tainjiawenzhen } from "@/request/index"; // 添加问诊
import { addFav } from "@/request/user"; // 添加/删除关注
import { getCommentList } from "@/request/user";
export default {
  components: {
    headerTop,
    headerMin,
    publicBottom
  },
  props: [],
  data() {
    return {
      searchState: 0,
      search: "",
      state: 2, // 步骤条
      UserArr: {}, // 医生 详情
      id: "",
      uid: "",
      isDefalutImg: false,
      isFollow: "", // 是否关注
      waiting_num: 0,
      commentList: [] // 评价内容
    };
  },
  created() {
    let ids = this.$route.query.id;
    this.id = this.$route.query.id;
    this.uid = this.$route.query.uid;
    this.start(ids);
  },
  methods: {
    tabImg() {},
    start(id) {
      getinfo({
        id: id,
        token: this.$token
      }).then(res => {
        this.UserArr = res.data;
        this.isFollow = this.UserArr.is_fav;
        this.waiting_num = res.data.waiting_num;
        if (res.data.waiting_num == -1) this.waiting_num = 0;
      });

      // 获取医生评论
      getCommentList({
        doctor_uid: this.uid
      }).then(res => {
        if (res.code == 1) {
          this.commentList = res.data.data;
          this.commentList = this.commentList.slice(0, 4);
        }
      });
    },
    GoWait(v) {
      let chat_type;
      if (v === "video") {
        chat_type = 1;
      } else {
        chat_type = 0;
      }
      tainjiawenzhen({
        token: this.$user.token,
        doctor_uid: this.uid,
        chat_type
      }).then(res => {
        if (res.code == 0) {
          this.$message.error(res.msg);
          return;
        }
        if (res.code == 400) {
          this.$message({
            type: "error",
            message: res.msg
          });
          return;
        }
        let data = res.data;
        this.$router.push({
          path: `/doctor/wait?id=${this.id}&uid=${this.uid}&inquiry_doctor_id=${data.inquiry_doctor_id}&inquiry_id=${data.inquiry_id}`
        });
      });
    },
    GoAllGoods() {
      this.$router.replace({ path: "/allGoods" });
    },
    setFollow() {
      // 设置关注/取消关注
      addFav({
        token: this.$token,
        doctor_uid: this.uid
      }).then(res => {
        if (res.code == 1) {
          this.isFollow = res.data.is_fav;
          this.$message({
            type: "success",
            message: "操作成功"
          });
        }
      });
    },
    getAllComment() {
      // 获取所有评价
      this.$router.push(
        `/doctor/allComment?cate_type=${this.UserArr.category_name}&doctor_name=${this.UserArr.realname}&uid=${this.uid}&type=2`
      );
    }
  }
};
</script>
<style lang="less" scoped>
@import "./doctor";
</style>